import * as React from "react";
import LinkedInIcon from "./icons/linkedin";
import FacebookIcon from "./icons/facebook";
import TwitterIcon from "./icons/twitter";
import GitHubIcon from "./icons/github";
import TiktokIcon from "./icons/tiktok";

const Socials = ({
  rowClass = "flex flex-row flex-wrap gap-x-4 gap-y-2 items-center",
  iconClass = "fill-black hover:fill-primary transition h-4"
}) => {

  const linkedInUrl = "https://www.linkedin.com/company/teckrowd";
  const gitHubUrl = "https://github.com/teckrowd";
  const twitterUrl = false;
  const facebookUrl = false;
  const tiktokUrl = false;
  
  return (
    <ul className={rowClass}>
      {linkedInUrl &&
        <li>
          <a href={linkedInUrl}
            target="_blank"
            rel="noreferrer">
            <LinkedInIcon className={iconClass} />
          </a>
        </li>}
      {twitterUrl &&
        <li>
          <a href={twitterUrl}
            target="_blank"
            rel="noreferrer">
            <TwitterIcon className={iconClass} />
          </a>
        </li>}
      {facebookUrl &&
        <li>
          <a href={facebookUrl}
            target="_blank"
            rel="noreferrer">
            <FacebookIcon className={iconClass} />
          </a>
        </li>}
      {tiktokUrl &&
        <li>
          <a href={tiktokUrl}
            target="_blank"
            rel="noreferrer">
            <TiktokIcon className={iconClass} />
          </a>
        </li>}
      {gitHubUrl &&
        <li>
          <a href={gitHubUrl}
            target="_blank"
            rel="noreferrer">
            <GitHubIcon className={iconClass} />
          </a>
        </li>}
    </ul>
  )
}

export default Socials;