exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-gktech-js": () => import("./../../../src/pages/projects/gktech.js" /* webpackChunkName: "component---src-pages-projects-gktech-js" */),
  "component---src-pages-projects-gt-skin-js": () => import("./../../../src/pages/projects/gt-skin.js" /* webpackChunkName: "component---src-pages-projects-gt-skin-js" */),
  "component---src-pages-projects-smith-kit-js": () => import("./../../../src/pages/projects/smith-kit.js" /* webpackChunkName: "component---src-pages-projects-smith-kit-js" */),
  "component---src-pages-projects-temp-and-agnew-js": () => import("./../../../src/pages/projects/temp-and-agnew.js" /* webpackChunkName: "component---src-pages-projects-temp-and-agnew-js" */)
}

