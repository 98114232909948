import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { globalHistory } from "@reach/router";
import clsx from "clsx";

const calcHeight = (el) => {
  const clone  = el.cloneNode(true);
  Object.assign(clone.style, {
    overflow: 'visible',
    height: 'auto',
    maxHeight: 'none',
    opacity: '0',
    visibility: 'hidden',
    display: 'block'
  });
  el.after(clone);
  const height = clone.offsetHeight;
  clone.remove();
  return height;
}

const NavItem = ({ to, label }) => {
  return (
    <li className="relative">
      <Link
        className="block transition whitespace-nowrap font-black font-heading text-white hover:text-white/80 text-4xl hover:scale-110"
        to={to}
      >
        {label}
      </Link>
    </li>
  );
};

const links = [
  {
    label: "Home",
    to: "/"
  },
  {
    label: "What We Do",
    to: "/#what-we-do"
  },
  {
    label: "Our Work",
    to: "/#our-work"
  },
  {
    label: "Contact Us",
    to: "/#contact-us"
  }
];

const Header = ({path}) => {
  const [mobileActive, setMobileActive] = React.useState(false);
  const [burgerActive, setBurgerActive] = React.useState(false);
  const aside = React.useRef();
  const header = React.useRef();

  React.useEffect(() => {
    const headerEl = header.current;
    const asideEl = aside.current;
    const headerHeight = calcHeight(headerEl.querySelector('nav'));
    const asideHeight = calcHeight(asideEl.querySelector('.contain'));
    headerEl.style.minHeight = `${headerHeight}px`;
    asideEl.style.minHeight = `${asideHeight}px`;
    asideEl.addEventListener('transitionend', (e) => {
      if(asideEl === e.target && asideEl.classList.contains('top-0')) {
        setBurgerActive(true)
      }
    })
    return () => {
      asideEl.removeEventListener('transitionend');
    }
  }, []);

  const handleSetActive = React.useCallback((active) => {
    const headerEl = header.current;
    const headerHeight = calcHeight(headerEl.querySelector('nav'));
    const asideEl = aside.current.querySelector('.contain');
    asideEl.style.paddingTop = `${headerHeight + 48}px`;
    if(!active) {
      setBurgerActive(false)
    }
    setMobileActive(active);
  }, [setMobileActive])

  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        handleSetActive(false);
      }
    });
  }, [handleSetActive]);

  return (
    <>
      <header className="z-30 relative"
        ref={header}>
        <nav className="py-2 lg:py-0 bg-dark fixed w-full">
          <div className="contain flex flex-nowrap items-center gap-x-3 py-2 lg:py-4">
            <div className="flex items-center">
              <Link to="/">
                <StaticImage
                  src="../images/Teckrowd_logo_dark_icon.png"
                  alt="Teckrowd Logo"
                  className="max-h-[50px] md:max-h-[60px]"
                  height={60}
                  objectFit="contain"
                  formats={["auto", "avif", "webp"]}
                  placeholder="blurred"
                  layout="constrained"
                />
              </Link>
            </div>
            <div className="flex-1 flex items-center justify-end">
            <button
                type="button"
                className="py-4 items-center pointer-events-auto"
                onClick={() => handleSetActive(!mobileActive)}
              >
                <span className="sr-only">Open main menu</span>
                <div className={clsx("w-[30px] h-[2px] md:w-[40px] md:h-[2px] relative m-0 md:mt-2 before:content-[''] before:w-[30px] before:md:w-[40px] before:h-[2px] before:md:h-[2px] before:rounded-sm before:absolute before:right-0 before:transition-transform before:duration-700 before:ease-in-out after:rounded-sm after:content-[''] after:h-[2px] after:md:h-[2px]  after:absolute after:right-0 after:transition-transform after:duration-700 after:ease-in-out", mobileActive ? "before:rotate-45 before:top-0 after:-rotate-45 after:top-0 after:w-[30px] after:md:w-[40px]" : "before:-top-1 after:top-1 after:w-[30px] after:md:w-[40px]", burgerActive ? "before:bg-white after:bg-white" : "before:bg-white after:bg-white hover:before:bg-white/80 hover:after:bg-white/80")}></div>
              </button>
            </div>
          </div>
        </nav>
      </header>

      <aside
        ref={aside}
        className={clsx(
          "bg-dark fixed h-full w-[100vw] z-20 shadow-lg transition-[top] ease-in duration-[1200ms]",
          {
            "top-[-150vh]": !mobileActive,
            "top-0": mobileActive,
          }
        )}>
        <div className="contain relative w-full h-screen flex flex-col px-12 py-12 overflow-auto">
          <ul className="flex flex-col gap-y-12 font-bold mb-16 flex-1 items-center justify-center">
            {links.map((i, k) => (
              <NavItem key={k} {...i} />
            ))}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Header;