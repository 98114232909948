import * as React from "react";
import { Link } from "gatsby";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import Socials from "./socials";

const FooterLink = ({
  to,
  label
}) => {
  return (
    <li className="relative text-center border-b pb-2 border-dark-title/20">
      <Link className="block text-dark-body hover:text-dark-body/80 uppercase"
        to={to}>
        {label}
      </Link>
    </li>
  )
}

const links = [
  {
    label: "Home",
    to: "/"
  },
  {
    label: "What We Do",
    to: "/#what-we-do"
  },
  {
    label: "Our Work",
    to: "/#our-work"
  },
  {
    label: "Community",
    to: "/#community"
  },
  {
    label: "Our Team",
    to: "/#our-team"
  },
  {
    label: "Contact Us",
    to: "/#contact-us"
  }
];

const Footer = () => {

  const {
    entity
  } = useSiteMetadata();
  
  return (
    <>
      <footer className="bg-dark-600 text-white pt-24 pb-4">
        <div className="contain flex flex-col gap-y-12">
          <div className="grid lg:grid-cols-4 gap-y-12">
            <div className="lg:col-start-2 lg:col-span-2">
              <ul className="grid sm:grid-cols-2 gap-y-4 gap-x-8">
                {links.map((i,k) => (
                  <FooterLink key={k}
                    {...i} />
                ))}
              </ul>
            </div>
            <Socials rowClass="lg:col-span-4 flex flex-row flex-wrap gap-x-4 gap-y-2 items-center justify-center"
                iconClass="fill-dark-title hover:fill-dark-title/80 transition h-6" />
          </div>
          <div className="flex flex-col md:flex-row gap-x-8 gap-y-4 py-6 border-t border-dark-title/20 items-center sm:items-start">
            <p className="flex-1 text-dark-body">&copy; {new Date().getFullYear()} {entity}</p>
            <Link to="/privacy"
              className="underline text-dark-body">Privacy Policy</Link>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;