import * as React from "react"
import Footer from "./footer";
import Header from "./header";
import { navigate } from '@reach/router';

const Layout = ({location, children}) => {
  const [lastPath, setLastPath] = React.useState(null);

  React.useEffect(() => {
    if(lastPath !== location.pathname) {
      setLastPath(location.pathname);
    }
    if(lastPath !== '/' && location.hash) {
      navigate(location.hash)
    }
  }, [location, lastPath]);

  return (
    <div className="relative flex flex-col h-full text-black opacity-1">
      <Header path={location.pathname} />
      <main className="grow">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout;